.Board {
    height: calc(100% - 50px);
    padding-top: 50px;
    display: flex;
}

.UserDetailControlDiv {
    flex: 0 0 35%;
}

.UserTableDiv {
    flex: 1;
    padding-left: 30px;
}